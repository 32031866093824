import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_Node-RED_Tutorials/PrimaryBox';
import PrimaryBox from 'components/Advanced_User/INSTAR_MQTT_Broker/Node-RED/PrimaryBox';
import NavButtons from 'components/Advanced_User/INSTAR_MQTT_Broker/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "INSTAR MQTT Server with Node-RED",
  "path": "/Advanced_User/INSTAR_MQTT_Broker/Node-RED/",
  "dateChanged": "2019-03-26",
  "author": "Mike Polinowski",
  "excerpt": "MQTT (Message Queuing Telemetry Transport) is a machine-to-machine (M2M)/ Internet of Things (IoT) connectivity protocol. It was designed as an extremely lightweight publish/subscribe messaging transport. It is useful for connections with remote locations where a small code footprint is required and/or network bandwidth is at a premium. All Full HD cameras now offer an internal MQTT broker: IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9010 Full HD, IN-9020 Full HD.",
  "image": "../AU_SearchThumb_MQTT.png",
  "social": "/images/Search/AU_SearchThumb_MQTT.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_MQTT_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "Node-RED"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='INSTAR MQTT Server with Node-RED' dateChanged='2019-03-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='MQTT (Message Queuing Telemetry Transport) is a machine-to-machine (M2M)/ Internet of Things (IoT) connectivity protocol. It was designed as an extremely lightweight publish/subscribe messaging transport. It is useful for connections with remote locations where a small code footprint is required and/or network bandwidth is at a premium. All Full HD cameras now offer an internal MQTT broker: IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9010 Full HD, IN-9020 Full HD.' image='/images/Search/AU_SearchThumb_MQTT.png' twitter='/images/Search/AU_SearchThumb_MQTT.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/INSTAR_MQTT_Broker/Node-RED/' locationFR='/fr/Advanced_User/INSTAR_MQTT_Broker/Node-RED/' crumbLabel="MQTT" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "instar-mqtt-server-with-node-red",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#instar-mqtt-server-with-node-red",
        "aria-label": "instar mqtt server with node red permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR MQTT Server with Node-RED`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#node-red-configuration"
        }}>{`Node-RED Configuration`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#node-red-json-flows"
            }}>{`Node-RED JSON Flows`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#node-red-user-interface"
        }}>{`Node-RED User Interface`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#multi-camera-setup"
        }}>{`Multi-Camera Setup`}</a></li>
    </ul>
    <PrimaryBox mdxType="PrimaryBox" />
    <h2 {...{
      "id": "node-red-configuration",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#node-red-configuration",
        "aria-label": "node red configuration permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED Configuration`}</h2>
    <p>{`If you aren't using Node-RED already please follow our tutorials on how to set up the program under `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Node-RED_and_MQTT/"
      }}>{`Windows`}</a>{`, `}<a parentName="p" {...{
        "href": "/en/Software/Linux/Node-RED/"
      }}>{`LINUX Debian`}</a>{` or on a `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Homematic_CCU3_and_RedMatic/"
      }}>{`Homematic CCU3`}</a>{`. If you are using a Raspberry Pi to run Node-RED, you can use the `}<a parentName="p" {...{
        "href": "https://nodered.org/docs/getting-started/raspberrypi"
      }}>{`official installation script`}</a>{` to have the program set up automatically for you.`}</p>
    <p>{`We prepared Node-RED flows for you that give you access to all camera functions through the MQTT Broker. All you need to do, is to click on the flow you want to use (`}<a parentName="p" {...{
        "href": "#node-red-json-flows"
      }}>{`see below`}</a>{`), copy it and paste it into the Node-RED import dialogue:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/82fb6a1856ebb83179e1a35cd2e60ad2/00d43/Node-RED_MQTT_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80.8695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAChUlEQVQoz22QzU/bMBiH+7duZ1qGBGOTOI0dOE7bcadpGwiBQEBbSkubT8duPtpy4cg2mqYtSxzbjZ20yZhSYCsbj36yXtt69Pp14eXq6qv19bW1teXl5aU5xZylYjGvS6ViqVSan+TFi5WVN5ubb7e2Xm9svH72vGAYwDCAbVkIIQghQhAhZFkdy3IQQrZtW5Zpmm0IoaaqGgA2AGalYh6fdD9/LegA1ptaS86vNAAVDQCgQKPWbiu6egi0A2jUVXlHkmoAGBChdktyKtVetWrLaqHcOP24+2mvfCAp6llLkTSEkGro+0Cv6VoFGmWEWkA7lOWGYUDLMg1omI7j9HqW4xRk/fyottuQKtf96+HQIyGmlFHKCWFBMMZ4jIOfvj8MAh9jjEPs+34+CULtdrsg1+vHX7abJ2XOOaUky7IkSeJYJNMkns7iZJZm6e0Cs9ms2+06jmNZVkHSwH61fiZrlFLf96MoikIa4XCCw0mQr1FIBGUPmQguer1ep9OxbbsAdP28UYcAYIyHnodxSLwxdV3qDujAo55HBx5x3T+ZEHZxcdFxnFw2TRMAYJpmFEWMsckkoqMbNhgwz2M/vrOrK3b1La8fEtEFGUIIDMNxOkKIOI45F/fyaMQsm7YUqqjs8jLf/i+39Sao71naGedcCJHL4xvmzTsPBnlcl/XdpzsD7bhZe6/L2+Je5uFwRPr9hyEHC+t85keyftJsfNC0HS743cvFJBKUccI4of+Gske/DeUj+egdOM87c85xGAoh0jT9dfs0aZr+lQ1VlaqnQJKFEEmShIQwxpI4TtM0WyBN09lslmXZdDpdkAGSzjVdgRhjugB5TDiHEBIEQbfbvZN/A8fKJysAPyHuAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/82fb6a1856ebb83179e1a35cd2e60ad2/e4706/Node-RED_MQTT_01.avif 230w", "/en/static/82fb6a1856ebb83179e1a35cd2e60ad2/d1af7/Node-RED_MQTT_01.avif 460w", "/en/static/82fb6a1856ebb83179e1a35cd2e60ad2/7f308/Node-RED_MQTT_01.avif 920w", "/en/static/82fb6a1856ebb83179e1a35cd2e60ad2/445fc/Node-RED_MQTT_01.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/82fb6a1856ebb83179e1a35cd2e60ad2/a0b58/Node-RED_MQTT_01.webp 230w", "/en/static/82fb6a1856ebb83179e1a35cd2e60ad2/bc10c/Node-RED_MQTT_01.webp 460w", "/en/static/82fb6a1856ebb83179e1a35cd2e60ad2/966d8/Node-RED_MQTT_01.webp 920w", "/en/static/82fb6a1856ebb83179e1a35cd2e60ad2/a5d4d/Node-RED_MQTT_01.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/82fb6a1856ebb83179e1a35cd2e60ad2/81c8e/Node-RED_MQTT_01.png 230w", "/en/static/82fb6a1856ebb83179e1a35cd2e60ad2/08a84/Node-RED_MQTT_01.png 460w", "/en/static/82fb6a1856ebb83179e1a35cd2e60ad2/c0255/Node-RED_MQTT_01.png 920w", "/en/static/82fb6a1856ebb83179e1a35cd2e60ad2/00d43/Node-RED_MQTT_01.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/82fb6a1856ebb83179e1a35cd2e60ad2/c0255/Node-RED_MQTT_01.png",
              "alt": "MQTT & Node-RED mit Ihrer INSTAR IP Kamera",
              "title": "MQTT & Node-RED mit Ihrer INSTAR IP Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "node-red-json-flows",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#node-red-json-flows",
        "aria-label": "node red json flows permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED JSON Flows`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Node-RED_Flows/MQTT-alarm-menu.json"
        }}>{`Alarm Menu`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Node-RED_Flows/MQTT-features-menu.json"
        }}>{`Features Menu`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Node-RED_Flows/MQTT-multimedia-menu.json"
        }}>{`Multimedia Menu`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Node-RED_Flows/MQTT-network-menu.json"
        }}>{`Network Menu`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Node-RED_Flows/MQTT-system-menu.json"
        }}>{`System Menu`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Node-RED_Flows/MQTT-task-menu.json"
        }}>{`Recording Task Menu`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/Node-RED_Flows/MQTT-multi-camera.json"
        }}>{`Multi Camera Setup`}</a></li>
    </ul>
    <p><strong parentName="p">{`Note`}</strong>{`: It makes sense to delete every sequence inside those flows that you are not going to use.`}</p>
    <p>{`Now double-click the first MQTT Node (the first on the left) and assign your INSTAR MQTT Broker to Node-RED:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "845px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4db41619ed8e5990efb3639a955fcfcb/8bd7c/Node-RED_MQTT_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABr0lEQVQoz1WRPY/TQBCG/Zsp+QWUUNBBwSkQaO5yjuNs/BV/Ow4JxNeBhChyNL672N5P27veRTYCiUejmWY00jOvlud5OpGkaZplaZJkWXY4HM7n86+Jqq7h/2BCHn/8LL991w7HY77f7/J8d/xyKopTUXw9FcXdXXW5YEwIIWgC/4VgTDkvXrzcP3uupVvPN83AXIdrEAIQgo2/MtM4aZqmLMumaaSUw4SUUkyDUlphRJTSYsd3dds1HEffOLrtLh17YUVBxhjt+15Jydu2Z6xjjLetGgYlxHilqrtbQwuD0DE+rvUPlgUc8Mky5+Zytg2dqhpVlVKoqZ8eHp7KElYXiiCFcHS4v2evXmtZkniruWtcBS5wN3MXvLfMqyi2IUSMMaVU17aMMkJIy1o2FmOs7fpeKaVFbuQYgbeOPcNzV5G3ihwjSuLPlJKuGzc4H02FEHIY1D+kVEJogetbtzfm4hoYa2u52CxvgH69DQKMMEZYSkkJqaqqrpumqRFCnHMhRN/3Ukot36WhPfPB2yS2Qn/me+9s602aAQjHYJRSlBAI4ZTX2CGEnPM/z/8NoWrTpc7mmtcAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4db41619ed8e5990efb3639a955fcfcb/e4706/Node-RED_MQTT_02.avif 230w", "/en/static/4db41619ed8e5990efb3639a955fcfcb/d1af7/Node-RED_MQTT_02.avif 460w", "/en/static/4db41619ed8e5990efb3639a955fcfcb/e50f5/Node-RED_MQTT_02.avif 845w"],
              "sizes": "(max-width: 845px) 100vw, 845px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4db41619ed8e5990efb3639a955fcfcb/a0b58/Node-RED_MQTT_02.webp 230w", "/en/static/4db41619ed8e5990efb3639a955fcfcb/bc10c/Node-RED_MQTT_02.webp 460w", "/en/static/4db41619ed8e5990efb3639a955fcfcb/c4f2e/Node-RED_MQTT_02.webp 845w"],
              "sizes": "(max-width: 845px) 100vw, 845px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4db41619ed8e5990efb3639a955fcfcb/81c8e/Node-RED_MQTT_02.png 230w", "/en/static/4db41619ed8e5990efb3639a955fcfcb/08a84/Node-RED_MQTT_02.png 460w", "/en/static/4db41619ed8e5990efb3639a955fcfcb/8bd7c/Node-RED_MQTT_02.png 845w"],
              "sizes": "(max-width: 845px) 100vw, 845px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4db41619ed8e5990efb3639a955fcfcb/8bd7c/Node-RED_MQTT_02.png",
              "alt": "MQTT & Node-RED mit Ihrer INSTAR IP Kamera",
              "title": "MQTT & Node-RED mit Ihrer INSTAR IP Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Type in your camera's IP address as MQTT Server. Choose the MQTT Server Port that you have set in the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/MQTT/"
      }}>{`MQTT Menu`}</a>{` inside your camera's WebUI - the default value is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`1883`}</code>{`.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "841px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/51b63d2f0932de5ad6bcf15663ac9f65/bad1b/Node-RED_MQTT_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.95652173913044%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABxUlEQVQoz02RS3PTMBSF/cfZseuC/8DQQhekZQqMiR0nqR35EVYMuwwT8nISkzSSLEtX8kMd2SSTb3U1mnMf51hRFCE0+TmdJkkyn89pTgkmx+Mxy7L9fp/t911BMKaUUkIIpfh0Wtju9tdvC4XRcOwHKPL9YDabYYwPh0PBDeIK3lEUUinO2PTN2z/f+5Y3Gt99fvz08MV23MViAUJQSpuWqqrqM82ZruZNnQNYru++f/pw++3OHtir5VKpUmtdlqVSqmkarXVzxeWptZYAFgrGjt1z7Ieh5yxXK845xlhKWde1UuqEcZ7nRWHuYIxhjBljRVEIIaSUlj/wnm7vv368H/addLstCpbneTeZ84JSyjkHAMEFzem/LOOMSSmVUkY8nqBef9D74XrPwTZNWW7+BOeUUMZMF91S13XVeqDPKKUshJDnOiPPC3x/t9tJgJeXE8GEMUYIIRgDgPGpbSP8Cb95918spRXHse/7QRCEIUrTtD0GyrIEgDYwE5IZWVWV1vLvQoZx3RpmJidJEoZRHCdRFG02G8EFCBACOo2Jtg28vlq4uYijEE2eR9MYhWiyXq+5uZZcfFItnfmdTpsNDADwClo6Wy28l7xNAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/51b63d2f0932de5ad6bcf15663ac9f65/e4706/Node-RED_MQTT_03.avif 230w", "/en/static/51b63d2f0932de5ad6bcf15663ac9f65/d1af7/Node-RED_MQTT_03.avif 460w", "/en/static/51b63d2f0932de5ad6bcf15663ac9f65/f15cc/Node-RED_MQTT_03.avif 841w"],
              "sizes": "(max-width: 841px) 100vw, 841px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/51b63d2f0932de5ad6bcf15663ac9f65/a0b58/Node-RED_MQTT_03.webp 230w", "/en/static/51b63d2f0932de5ad6bcf15663ac9f65/bc10c/Node-RED_MQTT_03.webp 460w", "/en/static/51b63d2f0932de5ad6bcf15663ac9f65/d02c2/Node-RED_MQTT_03.webp 841w"],
              "sizes": "(max-width: 841px) 100vw, 841px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/51b63d2f0932de5ad6bcf15663ac9f65/81c8e/Node-RED_MQTT_03.png 230w", "/en/static/51b63d2f0932de5ad6bcf15663ac9f65/08a84/Node-RED_MQTT_03.png 460w", "/en/static/51b63d2f0932de5ad6bcf15663ac9f65/bad1b/Node-RED_MQTT_03.png 841w"],
              "sizes": "(max-width: 841px) 100vw, 841px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/51b63d2f0932de5ad6bcf15663ac9f65/bad1b/Node-RED_MQTT_03.png",
              "alt": "MQTT & Node-RED mit Ihrer INSTAR IP Kamera",
              "title": "MQTT & Node-RED mit Ihrer INSTAR IP Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you want to use the TLS encryption, choose the SSL Port instead - default is `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`8883`}</code>{`. Now you have to open your camera's `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/MQTT/"
      }}>{`MQTT Menu`}</a>{` and download the Certificate (Client Key). And upload the certificate to Node-RED and save it:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "835px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/1bb9eb953225cea08dc43151bc48cdb9/f0685/Node-RED_MQTT_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABuUlEQVQoz1XQy27TQBiGYV8iF8DdcAWwQkJ000qJBElLWiep42NsTzJuHAklzYm0pZGoajuFMPYc8Mx4UBOB4Nn8q1/69GpRFEEI4zgej8cQQhACv+8vFovvT09pmm632yxLsyxNkiRNsyzLHh6+fb27vV2vN/f3GgDA9/3RaAQAiKKr0TgGg8H65oYQUhQF+iM/QIhw/vh54rx4OXnzVjMdv9W1z7u22/eB5/qmZent5WyOEEqShDEmpeSciz1+QOnj6ku62WgNvfm+fnTcPLY9J7Ac9/zSaOir6VwpRSkVQkhZSSmFEFVVqX8IKTUjMM7s05b7yQ1cz/asjn7RqE+mUy54UeSEYIRyvEcwkUrx6zm/ip+fhdB8/cKpnbj1WuA6YRj2ex8vm6+Xs5gyliMkhEAIEUqllBRjWlXsQ4O+OxJKcc413bRrrU691QkHw6E/sHS7e2asZsuy/JXneVmWGGNK6d/Nh3MIofWDwLQd07IhhKEXWnqvfdqeXy+VUhhjvm/FGKOUEkoIISVjoiyr6jmE5rluzzAcx44gDEHodk/0+qvpZEgI+7n7kef5brcrCqz+d6j+G9bp1cGLInZoAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1bb9eb953225cea08dc43151bc48cdb9/e4706/Node-RED_MQTT_04.avif 230w", "/en/static/1bb9eb953225cea08dc43151bc48cdb9/d1af7/Node-RED_MQTT_04.avif 460w", "/en/static/1bb9eb953225cea08dc43151bc48cdb9/7ace4/Node-RED_MQTT_04.avif 835w"],
              "sizes": "(max-width: 835px) 100vw, 835px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/1bb9eb953225cea08dc43151bc48cdb9/a0b58/Node-RED_MQTT_04.webp 230w", "/en/static/1bb9eb953225cea08dc43151bc48cdb9/bc10c/Node-RED_MQTT_04.webp 460w", "/en/static/1bb9eb953225cea08dc43151bc48cdb9/2321d/Node-RED_MQTT_04.webp 835w"],
              "sizes": "(max-width: 835px) 100vw, 835px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/1bb9eb953225cea08dc43151bc48cdb9/81c8e/Node-RED_MQTT_04.png 230w", "/en/static/1bb9eb953225cea08dc43151bc48cdb9/08a84/Node-RED_MQTT_04.png 460w", "/en/static/1bb9eb953225cea08dc43151bc48cdb9/f0685/Node-RED_MQTT_04.png 835w"],
              "sizes": "(max-width: 835px) 100vw, 835px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/1bb9eb953225cea08dc43151bc48cdb9/f0685/Node-RED_MQTT_04.png",
              "alt": "MQTT & Node-RED mit Ihrer INSTAR IP Kamera",
              "title": "MQTT & Node-RED mit Ihrer INSTAR IP Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now add the user login that you have set up in the  `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/MQTT/"
      }}>{`MQTT Menu`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "831px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5a37b6ae95bf8b1b5b01d9997d12b1b3/5b4a1/Node-RED_MQTT_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABRklEQVQY03XQS0/CQBAH8H5pj34PT3IwEOHgg6DSB2AprVYxMTEejDdpCyxQy7Kzrz4w7gp68XeY7GF35z9jhOF9EATj8VMYhlEUZVmWpukCoTiO4ySJoihRNfvM8B4hs+cX9PZuBMGdO/TC8NEdepPJByEEYwwAFIBSyhjTFX4RlucPB4evR8eG2Rs0Lzqtyyvb6U+nCcZ4tVqVSlEU5T8w5xvOjLbdrp3VGp261bNm01lZllVV6RtbpdrZ7uhzURSGO+rdOOdmr90fOEny3RlvNpRSClQIQQgRUuq/OOcYY8YY51xKmee54V7fdE/qVuP01nbQYkEB0jQlCqV0vV5zIXQ3AFgul2pfBACklIY1HDW7TqvruL6P5nMAEEJwzhljOuJ+Tp1WD/UT2/M82zRty/T9EUIIADjnuaJ2Vv5dWqHsH38BCGW2HHKmsvQAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5a37b6ae95bf8b1b5b01d9997d12b1b3/e4706/Node-RED_MQTT_05.avif 230w", "/en/static/5a37b6ae95bf8b1b5b01d9997d12b1b3/d1af7/Node-RED_MQTT_05.avif 460w", "/en/static/5a37b6ae95bf8b1b5b01d9997d12b1b3/ea25a/Node-RED_MQTT_05.avif 831w"],
              "sizes": "(max-width: 831px) 100vw, 831px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5a37b6ae95bf8b1b5b01d9997d12b1b3/a0b58/Node-RED_MQTT_05.webp 230w", "/en/static/5a37b6ae95bf8b1b5b01d9997d12b1b3/bc10c/Node-RED_MQTT_05.webp 460w", "/en/static/5a37b6ae95bf8b1b5b01d9997d12b1b3/6d405/Node-RED_MQTT_05.webp 831w"],
              "sizes": "(max-width: 831px) 100vw, 831px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5a37b6ae95bf8b1b5b01d9997d12b1b3/81c8e/Node-RED_MQTT_05.png 230w", "/en/static/5a37b6ae95bf8b1b5b01d9997d12b1b3/08a84/Node-RED_MQTT_05.png 460w", "/en/static/5a37b6ae95bf8b1b5b01d9997d12b1b3/5b4a1/Node-RED_MQTT_05.png 831w"],
              "sizes": "(max-width: 831px) 100vw, 831px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5a37b6ae95bf8b1b5b01d9997d12b1b3/5b4a1/Node-RED_MQTT_05.png",
              "alt": "MQTT & Node-RED mit Ihrer INSTAR IP Kamera",
              "title": "MQTT & Node-RED mit Ihrer INSTAR IP Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on add to add the INSTAR MQTT Broker to Node-RED and make sure that every MQTT Node of the flow is using the broker. After clicking on `}<strong parentName="p">{`Deploy`}</strong>{` all MQTT Nodes should show that they successfully connected:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9b5bd129fe0f14def46e358ed0bb7271/e548f/Node-RED_MQTT_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "26.08695652173913%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABIElEQVQY0x2Ny07CQBRA+1ku/QxXJqXTNiWGjTExrkxY+Bka9y5caIxLILJAEbC1PvqgAZW+ZkoLQ4eBuaacnJztkVSEVFVFCGmapiiKLMvKjlpNVjVNQUjX9Xq9bhiGqmvnp2e3B4c3e/vN45OjRkNi6/VyuVhSuhVbAaJqpQAhAEDsyjmnlGZpugJgdw9F82INkBMijQf2e8/+6n+6Q8cduc7AISGO83kvmLxMfuZFwRhLMfF8P8U4w5jMZlkYcsbiOJbMx1a/M7KeTKtrWV3zrWNG0zCeZ20/aHnj198wD73YbgdjP8+LxaLICC5XJQBgjKWZ/+E+X07t+2nQcu3rb+sq+hvyDZQbXsk3YstpQZIkiaIoSRJWshWlbHf+B8G78nj8xJ0CAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9b5bd129fe0f14def46e358ed0bb7271/e4706/Node-RED_MQTT_06.avif 230w", "/en/static/9b5bd129fe0f14def46e358ed0bb7271/d1af7/Node-RED_MQTT_06.avif 460w", "/en/static/9b5bd129fe0f14def46e358ed0bb7271/7f308/Node-RED_MQTT_06.avif 920w", "/en/static/9b5bd129fe0f14def46e358ed0bb7271/6d24a/Node-RED_MQTT_06.avif 975w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9b5bd129fe0f14def46e358ed0bb7271/a0b58/Node-RED_MQTT_06.webp 230w", "/en/static/9b5bd129fe0f14def46e358ed0bb7271/bc10c/Node-RED_MQTT_06.webp 460w", "/en/static/9b5bd129fe0f14def46e358ed0bb7271/966d8/Node-RED_MQTT_06.webp 920w", "/en/static/9b5bd129fe0f14def46e358ed0bb7271/1e975/Node-RED_MQTT_06.webp 975w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9b5bd129fe0f14def46e358ed0bb7271/81c8e/Node-RED_MQTT_06.png 230w", "/en/static/9b5bd129fe0f14def46e358ed0bb7271/08a84/Node-RED_MQTT_06.png 460w", "/en/static/9b5bd129fe0f14def46e358ed0bb7271/c0255/Node-RED_MQTT_06.png 920w", "/en/static/9b5bd129fe0f14def46e358ed0bb7271/e548f/Node-RED_MQTT_06.png 975w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9b5bd129fe0f14def46e358ed0bb7271/c0255/Node-RED_MQTT_06.png",
              "alt": "MQTT & Node-RED mit Ihrer INSTAR IP Kamera",
              "title": "MQTT & Node-RED mit Ihrer INSTAR IP Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "node-red-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#node-red-user-interface",
        "aria-label": "node red user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Node-RED User Interface`}</h2>
    <p>{`You are currently using the Node-RED admin panel under an URL looking something like this: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://<IP Address>:1880/#flow/b7397920.044be8`}</code>{`. To switch to the Node-RED dashboard simply add a `}<strong parentName="p">{`ui`}</strong>{` to the end of it like `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`http://<IP Address>:1880/ui/`}</code>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4e0ef9578304f7e8583846ad1f37d981/84a90/Node-RED_MQTT_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACRklEQVQoz0VRTWsUQRAdT948eNGjkF3dbLI70x/V393V3bM7k0mCBBRNIMqIgUjQJHh2xYMIOfh/d3OSzQZT1KHqweO9elUsfz9Z3jxf/nm6unm2+vV4tShWi2L5o1guitu7ebUobn+u1w24egAfFV+a4dXB9tfuVT8b9fWwz1uf663LbnjRDj6mrT4PNn0+H1x1w4u9l30efKqHH/DF9zdQUFAuRKn9qIRRCVSFKdPaojChkmFM5JhIppGAtg4JV9ulGEzkmNn5/lGhjc45IQYlBWckoVdK+OBjCN5Z4FQASzEoJWNEa0xJ+Ols92RWztuuMNbWdZ1zNkaXZeUxaa0QMUYEAEooAMQYlVLWx/f7/rieRk0dTJu2LYwxOeeUojIuaXLccMLXIt47zjmlD2Tl0mkn+vn2LpFVRep5U0gpQwjBe9Chb3b7+XinguC9AKCUMsY45yEEIYQ2rkuydRXjAKw6e9cWSqkYY0TUxh7YSetZxcB7DwDsrgDgjiwRgzFGcWIFTXLy96K5tx1j9Ih7ns6iE1JhTFrr/+QYoxACEZ3RhpcaiOM718exMGYTWOJCnzWj2hLCICJGRO+9VJIxFgI6STsU2XC2voVOyyrP1rY1YvROW0EmFfUBtRRzx1rPBaeEUEYpJdRoHVOWUlaElYQlr0/fHhZKSmXxqJbn7aikgjN6JxWM0WSdGAeAbhYAhHMuhJANFZwemPH1Sbr/szUaONvQpJTOWkTUSkpeRVV9O9xhoHwIKdjL11MtONBqr23+AcEvxV9q6OI9AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4e0ef9578304f7e8583846ad1f37d981/e4706/Node-RED_MQTT_07.avif 230w", "/en/static/4e0ef9578304f7e8583846ad1f37d981/d1af7/Node-RED_MQTT_07.avif 460w", "/en/static/4e0ef9578304f7e8583846ad1f37d981/7f308/Node-RED_MQTT_07.avif 920w", "/en/static/4e0ef9578304f7e8583846ad1f37d981/4a392/Node-RED_MQTT_07.avif 982w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4e0ef9578304f7e8583846ad1f37d981/a0b58/Node-RED_MQTT_07.webp 230w", "/en/static/4e0ef9578304f7e8583846ad1f37d981/bc10c/Node-RED_MQTT_07.webp 460w", "/en/static/4e0ef9578304f7e8583846ad1f37d981/966d8/Node-RED_MQTT_07.webp 920w", "/en/static/4e0ef9578304f7e8583846ad1f37d981/4c092/Node-RED_MQTT_07.webp 982w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4e0ef9578304f7e8583846ad1f37d981/81c8e/Node-RED_MQTT_07.png 230w", "/en/static/4e0ef9578304f7e8583846ad1f37d981/08a84/Node-RED_MQTT_07.png 460w", "/en/static/4e0ef9578304f7e8583846ad1f37d981/c0255/Node-RED_MQTT_07.png 920w", "/en/static/4e0ef9578304f7e8583846ad1f37d981/84a90/Node-RED_MQTT_07.png 982w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4e0ef9578304f7e8583846ad1f37d981/c0255/Node-RED_MQTT_07.png",
              "alt": "MQTT & Node-RED mit Ihrer INSTAR IP Kamera",
              "title": "MQTT & Node-RED mit Ihrer INSTAR IP Kamera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Every flow that you have imported above will be on it's own board. Clicking on toggles and swiping sliders allows you to change the settings on your camera.`}</p>
    <p><strong parentName="p">{`Note`}</strong>{` that the changes happen instantly, but some settings require a restart of your camera to become active - e.g. when changing a camera port, WDR settings, etc. As the WebUI is using the HTTP interface changes will not be reflected there unless you reload the menu.`}</p>
    <h2 {...{
      "id": "multi-camera-setup",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#multi-camera-setup",
        "aria-label": "multi camera setup permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Multi-Camera Setup`}</h2>
    <p>{`Node-RED is one of the few systems that allow you to connect `}<strong parentName="p">{`more than 1 MQTT Broker`}</strong>{`. This way you can connect all your cameras via it's own broker - without including a single point of failure to your security network.`}</p>
    <p>{`But you still might want to connect some of your cameras into groups, e.g. connect all your cameras that are connected by WiFi to a broker on a camera that has a Ethernet connection. In the following example we have two cameras:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`IN-8015 Full HD`}</strong>{` (`}<em parentName="li">{`192.168.2.73`}</em>{`, `}<em parentName="li">{`091419200118`}</em>{`) /`}<strong parentName="li">{`MQTT Client`}</strong></li>
      <li parentName="ul"><strong parentName="li">{`IN-9010 Full HD`}</strong>{` (`}<em parentName="li">{`192.168.2.165`}</em>{`, `}<em parentName="li">{`000389888811`}</em>{`) /`}<strong parentName="li">{`MQTT Broker`}</strong></li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/809224d899b6790bfba7f6be4e404f1b/00d43/Node-RED_MQTT_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "71.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7DAAAOwwHHb6hkAAACNklEQVQoz23QW0sbQRTA8f1MERLdxMS9z21nZq8zs2s22Zhar2m1KFboQzGJqC1SKe2TL33qV1BBUPvBykZKpc2f8/bjwOFodO97PL6Jx3fy4lGdP8rzB3H2kIzv1Okt2LrEu1fJ5HamT+r0Xp7citP7eHKnpjfkzTctGBz0D7+ku2d8Y8w3xsHmONic0J1pfvg1HL7n/Xf9o6t0dMY3x3xrEmxPw61JtD3JD67U6ERD0MmzNFOJTLhMeByQmJOPnVYv6eEwJcDKZZyLKA1pGtKQopCiwAdCrearqxrD+LXMXgmZCRnGCcIEYVJadkQ44SGlbG1t2OuX5WCt6PWTVDyPUqpa9gEoKJMAuoa5YhjmrJZlGZaFEMIYZ1kWRREhBGNM/ub7jGkAQsKZ5VYZfzINw7ZMyzI9z/N9357VeZGx0nFsW0MIiTQNgoC8DCMaKQdgCIAQ4j/FNFYe8jVCSFmWUkp/FqU+pYxSxpPMQwQjNFdZnEFCNQAAY8xxHKM61bItw+i02y3daOuWabieN1dXlnXHNjUIIeecVXEPs44NTcB8MaTZuu0hz3ODIJijat2FpDq72+3mea6U+rQX/zgix/s74uKpuPzlBF0E3KIo5qoX9aqHpWmaJEkUhQfDaLob7wxkVIzk4K0LCACeEGKuAkQ1jHEcVxZGUTj6HHz4WQ6OryG8plzYtgvh8+a/6nPpuFqj0dD1pj6r1VpuLbeXFpf0+mKzsVSr1er1erM5XxdqC78B/V4Z0HFE8F4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/809224d899b6790bfba7f6be4e404f1b/e4706/Node-RED_MQTT_08.avif 230w", "/en/static/809224d899b6790bfba7f6be4e404f1b/d1af7/Node-RED_MQTT_08.avif 460w", "/en/static/809224d899b6790bfba7f6be4e404f1b/7f308/Node-RED_MQTT_08.avif 920w", "/en/static/809224d899b6790bfba7f6be4e404f1b/445fc/Node-RED_MQTT_08.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/809224d899b6790bfba7f6be4e404f1b/a0b58/Node-RED_MQTT_08.webp 230w", "/en/static/809224d899b6790bfba7f6be4e404f1b/bc10c/Node-RED_MQTT_08.webp 460w", "/en/static/809224d899b6790bfba7f6be4e404f1b/966d8/Node-RED_MQTT_08.webp 920w", "/en/static/809224d899b6790bfba7f6be4e404f1b/a5d4d/Node-RED_MQTT_08.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/809224d899b6790bfba7f6be4e404f1b/81c8e/Node-RED_MQTT_08.png 230w", "/en/static/809224d899b6790bfba7f6be4e404f1b/08a84/Node-RED_MQTT_08.png 460w", "/en/static/809224d899b6790bfba7f6be4e404f1b/c0255/Node-RED_MQTT_08.png 920w", "/en/static/809224d899b6790bfba7f6be4e404f1b/00d43/Node-RED_MQTT_08.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/809224d899b6790bfba7f6be4e404f1b/c0255/Node-RED_MQTT_08.png",
              "alt": "Node-RED with your INSTAR IP Camera",
              "title": "Node-RED with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Since all INSTAR Full HD cameras use the same certificate for the `}<strong parentName="p">{`instar-ddns.de`}</strong>{` domain (`}<strong parentName="p">{`unless`}</strong>{` you `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/SSL_Certificate/"
      }}>{`uploaded your own certificates`}</a>{`) and both cameras are on the same network, we can directly add the IP address of our designated `}<em parentName="p">{`Broker Camera`}</em>{` to the `}<em parentName="p">{`Client Camera`}</em>{`. Only make sure that both are configured with the same MQTT ports and user login.`}</p>
    <p>{`First of all we now need to add our `}<em parentName="p">{`Broker Camera`}</em>{` as a new broker to Node-RED:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "839px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/84601c744fd43b16cb90da84680b6cab/d26aa/Node-RED_MQTT_09.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.30434782608696%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB9UlEQVQoz3XQTU8TURQG4Pmzrt3UtgyxGzdsJKEm/AASIUZBDJhCO9OW0qFjJ5bIzrJh2rFd1JmJ9+vc72qngBrjs7iLk7w5575OtVyubW66rlsulyuVirux4bputVotlUrPCq7r1mq154WX29v1nZ367u7Wq/qLrS1nNPocRcObmy/X16PZbMaFYIxhjNM0ywuMMaWULHDOpTU/4sn0+GP+9dYJgoHf6V0GYad7MR6PEUJ5nmOMSYFSSgjBDwhGYPTsU9R78vT29Mzxut3XR8f77z+ctbw4jhllaZoZY5bLpfkPay1bLqmUTrPX3DvZO2gcnLfP47tYKWWtVUoihBiAtXb5j/VQKeVc9Tte49BvHLX9ZjyZAECW5UIIKSUArL9AC4SQNMswxowxAJBSOheef7L/5vTgbaflzeYzKSWlVCmltRFCIIQoJayAMUnTlFLKOb8Pd4Orw5b/rul3+sG3JMGEKKU453meE0K01utTjTGruRCPx6/OHlwFHc/rtv2g35/P5xxgsVh8T1OEUJZlhBBjjNbaWiuFUEr9FQ7DsHfZ6wdBGIZJkoiCtRYACKVCSM6F1toYI6VUSq0Lvw9Hw2EYhsPiTZKEPwBgWmnGGKUUANbLH/3eHARBFEWDwWA6nQIAKhTFrDoXUgou1tv+9Kuwn7DhexfOZ+ClAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/84601c744fd43b16cb90da84680b6cab/e4706/Node-RED_MQTT_09.avif 230w", "/en/static/84601c744fd43b16cb90da84680b6cab/d1af7/Node-RED_MQTT_09.avif 460w", "/en/static/84601c744fd43b16cb90da84680b6cab/44676/Node-RED_MQTT_09.avif 839w"],
              "sizes": "(max-width: 839px) 100vw, 839px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/84601c744fd43b16cb90da84680b6cab/a0b58/Node-RED_MQTT_09.webp 230w", "/en/static/84601c744fd43b16cb90da84680b6cab/bc10c/Node-RED_MQTT_09.webp 460w", "/en/static/84601c744fd43b16cb90da84680b6cab/0a1be/Node-RED_MQTT_09.webp 839w"],
              "sizes": "(max-width: 839px) 100vw, 839px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/84601c744fd43b16cb90da84680b6cab/81c8e/Node-RED_MQTT_09.png 230w", "/en/static/84601c744fd43b16cb90da84680b6cab/08a84/Node-RED_MQTT_09.png 460w", "/en/static/84601c744fd43b16cb90da84680b6cab/d26aa/Node-RED_MQTT_09.png 839w"],
              "sizes": "(max-width: 839px) 100vw, 839px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/84601c744fd43b16cb90da84680b6cab/d26aa/Node-RED_MQTT_09.png",
              "alt": "Node-RED with your INSTAR IP Camera",
              "title": "Node-RED with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`To address each camera we can use their MQTT ID's (`}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/System/Info/"
      }}>{`LAN MAC Addresses`}</a>{`), which is `}<em parentName="p">{`091419200118`}</em>{` for the IN-8015FHD and `}<em parentName="p">{`000389888811`}</em>{` for the IN-9010FHD*. `}<strong parentName="p">{`Note that`}</strong>{` below instead of `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar/000389888811/alarm/area1/enable`}</code>{` we use the `}<strong parentName="p">{`LOCAL Topic`}</strong>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`instar/local/alarm/area1/enable`}</code>{` for the camera the broker is running on. This is optional, but saves you some typing:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Alarm Area 1`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`instar/091419200118/`}{`{`}{`status`}{`}`}{`/alarm/area1/enable`}</li>
          <li parentName="ul">{`instar/local/`}{`{`}{`status`}{`}`}{`/alarm/area1/enable`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Alarm Area 2`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`instar/091419200118/`}{`{`}{`status`}{`}`}{`/alarm/area2/enable`}</li>
          <li parentName="ul">{`instar/local/`}{`{`}{`status`}{`}`}{`/alarm/area2/enable`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Alarm Area 3`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`instar/091419200118/`}{`{`}{`status`}{`}`}{`/alarm/area3/enable`}</li>
          <li parentName="ul">{`instar/local/`}{`{`}{`status`}{`}`}{`/alarm/area3/enable`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Alarm Area 4`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`instar/091419200118/`}{`{`}{`status`}{`}`}{`/alarm/area4/enable`}</li>
          <li parentName="ul">{`instar/local/`}{`{`}{`status`}{`}`}{`/alarm/area4/enable`}</li>
        </ul>
      </li>
    </ul>
    <p><em parentName="p">{`* If you downloaded the `}<a parentName="em" {...{
          "href": "#node-red-json-flows"
        }}>{`Node-RED flow above`}</a>{` you can use your text editors `}<strong parentName="em">{`Search&Replace`}</strong>{` function to swap these MQTT ID's with the once from your cameras.`}</em></p>
    <p>{`Now that we have more than one camera on our MQTT network we are finally able to also use the `}<strong parentName="p">{`ALL Topic`}</strong>{` to send a update command for a function to all our cameras:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Alarm Area 1`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`instar/all/`}{`{`}{`status`}{`}`}{`/alarm/area1/enable`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Alarm Area 2`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`instar/all/`}{`{`}{`status`}{`}`}{`/alarm/area2/enable`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Alarm Area 3`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`instar/all/`}{`{`}{`status`}{`}`}{`/alarm/area3/enable`}</li>
        </ul>
      </li>
      <li parentName="ul"><strong parentName="li">{`Alarm Area 4`}</strong>
        <ul parentName="li">
          <li parentName="ul">{`instar/all/`}{`{`}{`status`}{`}`}{`/alarm/area4/enable`}</li>
        </ul>
      </li>
    </ul>
    <p>{`The resulting Node-RED flow can look something like this:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "778px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/01b6744f7565b9a1124c0d6a24f77d32/20982/Node-RED_MQTT_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "105.21739130434781%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAIAAADJt1n/AAAACXBIWXMAAA7DAAAOwwHHb6hkAAACkUlEQVQ4y5WTXW/aMBhG+f//ZNq0i2ldW1ZCKbRTF+KPfDiQEIIJNnGcGBoIrJSkE6vU0mhrNV9Yem0dHfvx68Zud19si2JTVI/V43+OxlIuKaETdxL5M+YzJRTNMnsueJ4rns18xgPGDvN8u9oeiCNFg46i/rkBWwi2IGiC0Am7rvvRJJDzYX+on+qH9QuANaxidWCrF7oRhQxq0Opads+2u3bkRbeef0KGjhA+9p+3yA1ZymUdpt5UP/sJLgBqQfgdTJywS8ing3nu9l39VActCFsQt/EiXtThOFtEIYujRM5VytVquWaUjmW62GynjM+CSMxEylMl1O5+Vw+sEDx2HDkYyMEwIeSORVQpR0ie54u5Yh7jAecBZz5LIlmW5Ss4GgT9Lx14eoPObsBJb4zcK3fwAdkGY77h6d/0ftMwmkb/3PANv9y/hqdjbmjI7DnWNTGvHDqMbofDr2Rox2JkBqiNnrOcWJO6mY4i/VwHGkRtCL6D0BlfE/ezSTCfe9DrNw2oQaRB2EIBCupmkWZ0NI1pnLI0mclc3bHpNEik2mxmPI6CSERCslTO0jzLj6M+wLUAq7Kq9uVjWVb7snbIv7TncaGyhXVtW13Tunasnh3i8GH38PS2T6MOZ+u1l0gvSeVqlcSJcQmhBnAH40szgMET/E8zX94BNgc8ni2XMk5gB+M2Njum2TFHYPQOfFxkmcI9y+nZ5AchNyTE4X63fwuuqkNG5Z/7FMXGJ+OpS5+6KmXp25m9mKuqkrGEGoIXh2+AWsjv+/uHN82TTHXGtBPSMM0SLsAlxpfI6tnWlRWg4B14u9upolDrorj/JeaJoUFwYcA2Bi3ovWs+LhZ5bhqOjzw6oJRQQUWtH2vwb0UAhryxDmRyAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/01b6744f7565b9a1124c0d6a24f77d32/e4706/Node-RED_MQTT_10.avif 230w", "/en/static/01b6744f7565b9a1124c0d6a24f77d32/d1af7/Node-RED_MQTT_10.avif 460w", "/en/static/01b6744f7565b9a1124c0d6a24f77d32/e8100/Node-RED_MQTT_10.avif 778w"],
              "sizes": "(max-width: 778px) 100vw, 778px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/01b6744f7565b9a1124c0d6a24f77d32/a0b58/Node-RED_MQTT_10.webp 230w", "/en/static/01b6744f7565b9a1124c0d6a24f77d32/bc10c/Node-RED_MQTT_10.webp 460w", "/en/static/01b6744f7565b9a1124c0d6a24f77d32/10884/Node-RED_MQTT_10.webp 778w"],
              "sizes": "(max-width: 778px) 100vw, 778px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/01b6744f7565b9a1124c0d6a24f77d32/81c8e/Node-RED_MQTT_10.png 230w", "/en/static/01b6744f7565b9a1124c0d6a24f77d32/08a84/Node-RED_MQTT_10.png 460w", "/en/static/01b6744f7565b9a1124c0d6a24f77d32/20982/Node-RED_MQTT_10.png 778w"],
              "sizes": "(max-width: 778px) 100vw, 778px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/01b6744f7565b9a1124c0d6a24f77d32/20982/Node-RED_MQTT_10.png",
              "alt": "Node-RED with your INSTAR IP Camera",
              "title": "Node-RED with your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We are now able to switch the Alarm Areas of both cameras through one MQTT broker. By combining cameras to a MQTT network group we are also able to use the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ALL Topic`}</code>{` to update settings on all cameras at once:`}</p>
    <p><img parentName="p" {...{
        "src": "/en/461735b3cd1074e84c6061f65335a053/Node-RED_MQTT_11.gif",
        "alt": "Node-RED with your INSTAR IP Camera"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      